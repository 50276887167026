import { useToast, ToastPosition, ToastPluginApi } from 'vue-toast-notification';

type ToastType = 'success' | 'error' | 'info' | 'warning';

interface ToastOptions {
    message: string;
    type?: ToastType;
    duration?: number;
    position?: ToastPosition;
}

export const showToast = ({
        message,
        type = 'error',
        duration = 4000,
        position = 'top-right',
    }: ToastOptions): void => {
    const toast: ToastPluginApi = useToast();

    toast.open({
        message,
        type,
        position,
        duration,
    });
};
